.social-links {
    text-align: center; }

a.social-icon {
    margin: 0 15px 30px 0;
    display: inline-block;
    color: #ccc;
    @include font-size(24);
    border: none;

    i {
        font-style: normal; }

    &:hover,
    &:active {
        border: none; } }
