@import "vendor/font-awesome.min";
@import "bourbon/bourbon";

@import "mixins";
@import "type";
@import "animate";

@import "partials/menu";

@import "partials/loop";
@import "partials/main-content";
@import "partials/author";
@import "partials/post-social";
@import "partials/post-prev-next";
@import "partials/contact";

@import "nojs";

html,
body {
    font-size: 62.5%;
    height: 100%;
    background: #222; }

.hide {
    position: absolute !important;
    top: -9999px !important;
    left: -9999px !important; }

.overlay {
    @include linear-gradient(to bottom, rgba(23,23,23,0.0), rgba(27,27,27,0.5));
    height: 100%;
    position: relative; }

.full {
    height: 100%;
    background-color: #333337; }

.ribbon-wrapper {
    width: 85px;
    height: 88px;
    overflow: hidden;
    position: absolute;

    .ribbon {
        font: bold 15px sans-serif;
        text-align: center;
        -webkit-transform: rotate(-45deg);
        -moz-transform:    rotate(-45deg);
        -ms-transform:     rotate(-45deg);
        -o-transform:      rotate(-45deg);
        position: relative;
        padding: 7px 0;
        top: 15px;
        left: -30px;
        width: 120px;
        color: #fff; }

    .small {
        font: bold 13px sans-serif; }

    .yellow {
        color: #333;
        background-color: #ebb134; }

    .blue {
        color: #333;
        background-color: #34c1eb; }

    .green {
        color: #333;
        background-color: #34eb5b; } }

.menu {
    background: #333;
    width: 100%;
    display: block;
    overflow: hidden;

    div {
        display: block;
        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;

            li {
                float: left;
                text-align: center;
                height: 49px;
                line-height: 49px;
                padding-left: 15px;
                padding-right: 15px;
                transition: all 0.3s ease;
                color: white;
                font-size: 20px;
                margin: 0;

                &:hover {
                    background: #444; }

                a {
                    border-bottom: 0; } } } }
    .socialmedia {
        float: left;

        ul {
            li {
                padding: 0px;
                a {
                    margin: 0px;
                    display: block;
                    width: 100%;
                    height: 100%; } } } }

    .mobile-menu {
        float: right;
        display: none; }

    .social {
        width: 49px; }

    .twitch {
        background: #6441A4;
        color: white; }

    .twitter {
        background: #55ACEE;
        color: white; }

    .github {
        background: #333;
        color: white; }

    .linkedin {
        background: #007bb5;
        color: white; }

    .flattr {
        background: #6DB02F;
        color: white; }

    .youtube {
        background: #bb0000;
        color: white; }

    .paypal {
        background: #00457C;
        color: white; }

    .rss {
        background: #d36311;
        color: white; } }

@media (max-width: 768px) {
    .menu {
        display: grid;
        div {
            ul {
                li {
                    width: 100%; } } }

        .mobile-menu {
            display: block; }

        .mainmenu {
            display: none; } } }

@media (min-width: 769px) {
    .menu {
        .mainmenu {
            display: block; } } }
