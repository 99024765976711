.post-prev-next {
    padding: 10px 0 30px;
    background-color: #F5F5F5;
    border-top: solid 1px #E9E9E9;

    .row {
        margin-right: 0px; }

    .read-more {
        color: #333337; }

    .read-more--prev {
        text-align: center;
        @include breakpoint(sm) {
            text-align: left; } }


    .read-more--next {
        text-align: center;
        @include breakpoint(sm) {
            text-align: right; } }

    .read-more__title {
        @include font-size(16);

        @include breakpoint(sm) {
            @include font-size(20); }

        @include breakpoint(md) {
            @include font-size(22); } }

    .read-more__subtitle {
        font-family: $sans;
        margin-bottom: 10px;
        text-transform: uppercase;
        letter-spacing: 5px;
        font-weight: 700;
        @include font-size(10);

        @include breakpoint(sm) {
            @include font-size(12); }

        @include breakpoint(md) {
            @include font-size(14); }

        .fa-chevron-right {
            display: none;
            @include breakpoint(sm) {
                display: inline;
                margin: 0 -35px 0 15px; } }

        .fa-chevron-left {
            display: none;
            @include breakpoint(sm) {
                display: inline;
                margin: 0 15px 0 -31px; } } } }


