.author-details {
    color: #fff;
    width: 80%;
    margin: 0 auto;
    @include clearfix;

    @include breakpoint(md) {
        padding: 100px 0;
        width: 60%; } }

.author-details--post-footer {
    @include breakpoint(md) {
        padding: 20px 0;
        width: 60%; } }

.author-details--dark {
    color: #333337; }

.author-details__date {
    font-size: 16px;
    margin: 0; }

.author-details__name {
    @include font-size(26);
    @include line-height(30);
    margin: 0 0 20px 0; }

.author-details__name--dark {
    color: #333337; }

.author-details__bio {
    @include font-size(16);
    @include line-height(20);

    margin: 0; }

.author-details__spacer {
    margin: 20px 0; }

.author-details__info {
    float: left;
    margin-left: 20px;
    width: 100%;
    padding: 0 70px 0 20px;

    @include breakpoint(sm) {
        padding: 0 300px 0 20px; }

    @include breakpoint(md) {
        width: 70%;
        padding: 0 20px; } }


.author-details__image {
    float: left;
    height: 80px;
    width: 80px;
    border-radius: 50%;
    margin: 75px 0 20px 40px;

    @include breakpoint(md) {
        margin: 75px 0 0 0; } }

.author-details__bio {
    margin-bottom: 20px; }

.author-details__extra {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 300;
    font-size: 12px;
    color: #eee; }

.author-details__extra--dark {
    color: #111222; }

.author-details__link {
    font-weight: 500;
    color: #fff; }

.author-details__link--dark {
    color: #333337; }

.author-details__nop {
    @include font-size(16);
    @include line-height(20);
    margin-bottom: 20px; }






