// Render Deprecated Webkit Gradient - Linear || Radial
//************************************************************************//
@function _deprecated-webkit-gradient($type,
                                     $deprecated-pos1, $deprecated-pos2,
                                     $full,
                                     $deprecated-radius1: false, $deprecated-radius2: false) {
  $gradient-list: ();
  $gradient: false;
  $full-length: length($full);
  $percentage: false;
  $gradient-type: $type;

  @for $i from 1 through $full-length {
    $gradient: nth($full, $i);

    @if length($gradient) == 2 {
      $color-stop: color-stop(nth($gradient, 2), nth($gradient, 1));
      $gradient-list: join($gradient-list, $color-stop, comma);
    }
    @else if $gradient != null {
      @if $i == $full-length {
        $percentage: 100%;
      }
      @else {
        $percentage: ($i - 1) * (100 / ($full-length - 1)) + "%";
      }
      $color-stop: color-stop($percentage, $gradient);
      $gradient-list: join($gradient-list, $color-stop, comma);
    }
  }

  @if $type == radial {
    $gradient: -webkit-gradient(radial, $deprecated-pos1, $deprecated-radius1, $deprecated-pos2, $deprecated-radius2, $gradient-list);
  }
  @else if $type == linear {
    $gradient: -webkit-gradient(linear, $deprecated-pos1, $deprecated-pos2, $gradient-list);
  }
  @return $gradient;
}
